import React from "react"
import { Layout } from "../components"
import { useStaticQuery, graphql } from "gatsby"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      family: file(relativePath: { eq: "family.jpg" }) {
        publicURL
      }
    }
  `)
  return (
    <Layout>
      <div className="bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              About Us
            </h1>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="relative pb-7/12 lg:pb-0">
                    <img
                      src={data.family.publicURL}
                      alt=""
                      width="1184"
                      height="1376"
                      className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto"
                    />
                  </div>
                  <figcaption className="flex mt-3 text-sm text-gray-500">
                    <svg
                      className="flex-none w-5 h-5 mr-2 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    The Owner's: Roger, Maria and Big(Jayden)
                  </figcaption>
                </figure>
              </div>
            </div>
            <div>
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <p className="text-lg leading-7 text-gray-500 mb-5">
                  Roger and Maria are the owners of Big’s Axe Throwing. The
                  (big) guy you see in the middle is Big! We all 3 love throwing
                  axes and spend a lot of time in the shop to help keep it clean
                  and fun! We got started back in August of 2019. Each of us
                  have our own story. Feel free to read.
                </p>
              </div>
              <div className="prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 pt-4">
                <h2>Meet Maria</h2>
                <p>
                  I threw my very first axe in Jacksonville, NC while my husband
                  was on active duty. It was not easy for me to get it to stick
                  much less to achieve my first bullseye. It literally took me
                  an hour and a half to nail my first bullseye. I almost gave
                  up, but that’s not the kind of person I am. After being able
                  to throw multiple bullseyes, I couldn’t help but to share with
                  my family and friends. I am now able to coach and assist
                  others to obtain their first bullseye! Come on down & I can
                  show you how a girl can throw! Axe on!
                </p>
              </div>
              <div className="prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 pt-4">
                <h2>Meet Roger</h2>
                <p>
                  I am a Marine veteran. I started throwing my first axe in
                  Jacksonville, NC. I actually was throwing with my off hand
                  because my dominant hand was broken. After surgery and some
                  therapy, I gained enough strength in my dominant hand to be
                  able to toss an axe. I would go throw axes once a week for two
                  hours consecutively with my buddy Scott. I knew if I was able
                  to throw with my dominant hand and get it to stick- it was
                  going to be a thing. Sure enough! I learned the ins and outs
                  of axe throwing to where I could learn how to assist others in
                  getting their axes to stick. After I got out of the Marine
                  Corps, I came back home to Dalton, Ga and talked to Maria
                  about opening an axe throwing venue here for the community
                  being that Dalton did not have one. Once we decided on doing
                  it, the journey began! Axe on!
                </p>
              </div>
              <div className="prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 pt-4">
                <h2>Meet Big</h2>
                <p>
                  Hi all! My real name is axe-ually Jayden. My dad started
                  calling me Big ever since he first saw me. Wait until I get
                  older{" "}
                  <span role="img" aria-label="smile emoji">
                    😉
                  </span>{" "}
                  I have my own axes that I throw down lane. Don’t worry people-
                  they are child friendly. The foam “blades” and foam handles
                  allow me to focus on my form until I am able to pick up a real
                  axe. If you have kids and want to come axe throwing, bring
                  them in too. I can share my axes with them. However, I may or
                  may not be there as I understand it could possibly get crowded
                  in there with a bunch of axes flying everywhere; my mom or dad
                  should be there and I gave them permission to share my axes
                  with other kids whom may be unable to throw due to their age.
                  If you see any of us in the shop as you pass by, feel free to
                  stick your head in and say hi! I promise we don’t bite. We
                  just throw axes{" "}
                  <span role="img" aria-label="smile emoji">
                    😊
                  </span>{" "}
                  Axe on!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
